import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import AuthRoutes from '@/modules/auth/routes';
import PublicRoutes from '@/modules/public/routes';
import AccessGroupUserRoutes from '@/modules/roles/access-group-user/routes';
import ExternalPartnerRoutes from '@/modules/roles/external-partner/routes';
import OrgRoleRoutes from '@/modules/roles/org-role-property-role/routes';
import SysmasterRoutes from '@/modules/roles/sysmaster/routes';
import Init from '@/pages/init.vue';
import http from '@/plugins/http';
import { eventBus } from '@/plugins/primevue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'init',
    // redirect is handled by auth-guard.vue
    component: Init,
  },
  ...SysmasterRoutes,
  ...OrgRoleRoutes,
  ...ExternalPartnerRoutes,
  ...AccessGroupUserRoutes,
  ...PublicRoutes,
  ...AuthRoutes,
  {
    path: '/unexpected-error',
    name: 'errors.unexpected-error',
    component: () => import('../pages/unexpected-error.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'errors.not-found',
    component: () => import('../pages/page-not-found.vue'),
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    eventBus.emit('beforeEachRoute', to);
  }
  next();
});
router.beforeResolve((to, from, next) => {
  if (to.path !== from.path) {
    http.abort('Page route changed');
  }
  next();
});
router.afterEach((to, from) => {
  if (to.path !== from.path) {
    eventBus.emit('afterEachRoute', to);
  }
});

export default router;
