import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  name: 'RequestsState',
  namespaced: true,
})
export default class RequestsState extends VuexModule {
  private globalHttpRequestsPendingVal = false;

  get globalHttpRequestsPending() {
    return this.globalHttpRequestsPendingVal;
  }

  @Mutation globalLoading(val: boolean) {
    this.globalHttpRequestsPendingVal = val;
  }
}
