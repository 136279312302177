import { AccessPolicyRole, MePropertyData, PropertyRolePermission } from './interfaces';

export default class Property {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private readonly property: MePropertyData) {}

  public get id() {
    return this.property.id;
  }

  public propertyRoles(id?: string) {
    return id ? this.property.propertyRoles?.filter((r) => r.id === id) : this.property.propertyRoles;
  }

  public hasPropertyRoleId(id: string) {
    return !!this.property.propertyRoles?.some((r) => r.id === id);
  }

  public hasPropertyRole(role?: PropertyRolePermission) {
    return role ? !!this.property.propertyRoles?.some((r) => r.role === role) : !!this.property.propertyRoles?.length;
  }

  public accessPolicyRoles(id?: string) {
    return id ? this.property.accessGroupRoles?.filter((r) => r.id === id) : this.property.accessGroupRoles;
  }

  public hasAccessPolicyRoleId(id: string) {
    return !!this.property.accessGroupRoles?.some((r) => r.id === id);
  }

  public hasAccessPolicyRole(role?: AccessPolicyRole) {
    return role ? !!this.property.accessGroupRoles?.some((r) => r.accessPolicyRole === role) : !!this.property.accessGroupRoles?.length;
  }

  public externalPartnerRoles(id?: string) {
    return id ? this.property.externalPartnerRoles?.filter((r) => r.id === id) : this.property.externalPartnerRoles;
  }

  public hasExternalPartnerRoleId(id: string) {
    return !!this.property.externalPartnerRoles?.some((r) => r.id === id);
  }

  public hasExternalPartnerRole() {
    return !!this.property.externalPartnerRoles?.length;
  }
}
