import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

import {
  MeData, Org, Property, Roles,
} from './interfaces';
import Me from './me';
import CurrentProperty from './current-property';

@Module({
  name: 'AppState',
  namespaced: true,
})
export default class AppState extends VuexModule {
  private meData: Me | null = null;

  private currentOrgData: Org | null = null;

  private currentPropertyData: Property | null = null;

  private rememberMeSetting = false;

  private pushNotifyRegisteredSetting = false;

  private ticketAccordionData: string|null|undefined|string[] = 'comments';

  private taskAccordionData: string|null|undefined|string[] = 'comments';

  private accessPolicyAccordionData: string|null|undefined|string[] = null;

  private unreadNotificationsSetting = 0;

  private currentRoleData: Roles | null = null;

  private lastRouteData: string|null = null;

  // getters
  get me() { return this.meData; }

  get role() { return this.currentRoleData; }

  get currentOrg() { return this.currentOrgData; }

  get currentProperty() {
    return this.currentPropertyData ? new CurrentProperty(this.currentPropertyData) : this.currentPropertyData;
  }

  get rememberMe() { return this.rememberMeSetting; }

  get pushNotifyRegistered() { return this.pushNotifyRegisteredSetting; }

  get unreadNotifications() { return this.unreadNotificationsSetting; }

  get ticketsAccordion() { return this.ticketAccordionData; }

  get tasksAccordion() { return this.taskAccordionData; }

  get accessPolicyAccordion() { return this.accessPolicyAccordionData; }

  get lastRoute() {
    return this.lastRouteData;
  }

  @Mutation setMe(value: MeData) {
    this.meData = new Me(value);
  }

  @Mutation setRole(value: Roles) {
    this.currentRoleData = value;
  }

  @Mutation setCurrentOrg(value: Org | null) {
    this.currentOrgData = value;
  }

  @Mutation setCurrentProperty(value: Property | null) {
    this.currentPropertyData = value;
  }

  @Mutation setPushNotifyRegistered(value: boolean) {
    this.pushNotifyRegisteredSetting = value;
  }

  @Mutation setUnreadNotifications(value: number) {
    this.unreadNotificationsSetting = value;
  }

  @Mutation setTicketAccordion(value: string|null|undefined|string[]) {
    this.ticketAccordionData = value;
  }

  @Mutation setTaskAccordion(value: string|null|undefined|string[]) {
    this.taskAccordionData = value;
  }

  @Mutation setAccessPolicyAccordion(value: string|null|undefined|string[]) {
    this.accessPolicyAccordionData = value;
  }

  @Mutation setLastRoute(value: string | null) {
    this.lastRouteData = value;
  }

  @Mutation async setRememberMe(value: boolean) {
    this.rememberMeSetting = value;
    // reload cognito config if cognito exists
    const Cognito = document.querySelector('lar-cognito-config') as null | HTMLLarCognitoConfigElement;
    const storage = value ? window.localStorage : window.sessionStorage;
    await Cognito?.setStorage(storage);
  }

  @Mutation clear() {
    this.meData = null;
    this.currentRoleData = null;
    this.currentOrgData = null;
    this.rememberMeSetting = false;
    this.pushNotifyRegisteredSetting = false;
    this.lastRouteData = null;
  }
}
