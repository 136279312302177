import { MeOrgData, Org as OrgModel, OrgRolePermission } from './interfaces';
import Property from './me-property';

export default class Org {
  public readonly properties: Property[];

  constructor(private readonly org: MeOrgData) {
    this.properties = this.org.properties?.map((p) => new Property(p)) ?? [];
  }

  public plain(): OrgModel {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { properties, ...org } = this.org;
    return org ?? null;
  }

  public get id() {
    return this.org.id;
  }

  public orgRoles(id?: string) {
    return id ? this.org.orgRoles?.filter((r) => r.id === id) : this.org.orgRoles;
  }

  public hasOrgRole(role?: OrgRolePermission) {
    return role ? !!this.org.orgRoles?.some((r) => r.role === role) : !!this.org.orgRoles?.length;
  }

  public hasOrgRoleId(id: string) {
    return !!this.org.orgRoles?.some((r) => r.id === id);
  }

  public getProperty(id: string) {
    return this.properties.find((p) => p.id === id);
  }
}
