import {
  MeData,
  Roles,
  SystemRolePermission,
} from './interfaces';
import Org from './me-org';

export default class Me {
  public readonly orgs: Org[];

  constructor(private readonly me: MeData) {
    this.orgs = this.me.orgs.map((o) => new Org(o));
  }

  public getOrg(id: string) {
    return this.orgs.find((o) => o.id === id);
  }

  public orgRoleList(orgId: string) {
    const org = this.getOrg(orgId);
    const roles: Roles[] = [];
    if (org?.hasOrgRole()) {
      roles.push(Roles.ORG_ROLE);
    }
    if (org?.properties.some((p) => p.hasPropertyRole())) {
      roles.push(Roles.PROPERTY_ROLE);
    }
    if (org?.properties.some((p) => p.hasAccessPolicyRole())) {
      roles.push(Roles.ACCESS_GROUP);
    }
    if (org?.properties.some((p) => p.hasExternalPartnerRole())) {
      roles.push(Roles.EXTERNAL_PARTNER);
    }
    return roles;
  }

  public hasSystemRole(role?: SystemRolePermission) {
    return role ? this.me.systemRole?.role === role : !!this.me.systemRole?.role;
  }

  public hasSystemRoleId(id: string) {
    return this.me.systemRole?.id === id;
  }
}
