import md5 from '@/components/helpers/md5';
import { Property, PropertyFeatureFlags, PropertyPrivacyLevel } from './interfaces';

export default class CurrentProperty implements Property {
  id!: string;

  name!: string;

  alias!: string;

  orgId!: string;

  line1!: string;

  line2!: string | null;

  city!: string;

  state!: string;

  zip!: string;

  country!: string;

  featureFlags!: null | PropertyFeatureFlags[];

  privacyLevel!: PropertyPrivacyLevel;

  createdAt!: Date;

  updatedAt!: Date;

  postalCode!: string | null;

  countryCode!: string | null;

  countrySubdivisionCode!: string | null;

  latitude!: string | null;

  longitude!: string | null;

  timezone!: string | null;

  constructor(property: Property) {
    Object.assign(this, property);
  }

  public hasFeature(flag: PropertyFeatureFlags) {
    return !!this.featureFlags?.includes(flag);
  }

  /**
   * Generates a unique key based on the current feature flags.
   * This can be used as a Vue :key parameter to force re-rendering
   * of a section when feature flags change.
   */
  public get featureFlagsKey() {
    return md5(this.featureFlags ? this.featureFlags.join('') : 'n');
  }
}
